<template>
  <div class="addOtherAccount-wrap">
    <div class="ub ub-cen footer-btn" @click="popupShowFn">
      <div class="ub ub-cen">
        <div class="footer-btn ub ub-cen text-c" v-if="verificationType === 'email'">{{$t('profile.add') + ' ' + $t('profile.email')}}</div>
        <div class="footer-btn ub ub-cen text-c" v-if="verificationType === 'phone'">{{$t('profile.add') + ' ' + $t('profile.phone')}}</div>
      </div>
    </div>
    <van-popup v-model="popupShow" position="bottom" :overlay="true" @closed="closedPopupFn">
      <div class="verification-wrap padding-top30">
        <div class="verification-index-main border-top-radius30">
          <div class="verification-index-header-title ub ub-btw padding30">
            <div></div>
            <div class="close" @click="closePopupShowFn">
              <img
                src="@/assets/images/system/cross.svg"
                alt="close"
                title="close"
              />
            </div>
          </div>
          <VerificationCode v-if="step === 1" :verificationType="verificationType" @onStep="onStep"></VerificationCode>
          <SetOtherAccount v-if="step === 2" :verificationType="verificationType"></SetOtherAccount>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import VerificationCode from './verificationCode.vue'
import SetOtherAccount from './setOtherAccount.vue'
export default {
  name: 'AddOtherAccount',
  props: {
    verificationType: {
      type: String,
      default: ''
    }
  },
  components: {
    VerificationCode, SetOtherAccount
  },
  computed: {
  },
  data () {
    return {
      popupShow: false,
      step: 1
    }
  },
  methods: {
    popupShowFn () {
      this.popupShow = true
      this.$EventBus.$emit('changePopupShow', this.popupShow)
      this.userInfo = this.$store.state.userInfo
    },
    closedPopupFn () {
      this.$EventBus.$emit('changePopupShow', this.popupShow)
    },
    closePopupShowFn () {
      this.popupShow = false
      this.$EventBus.$emit('changePopupShow', this.popupShow)
    },
    onStep (stepVal) {
      this.step = stepVal
    }
  }
}
</script>
<style lang="less">
.addOtherAccount-wrap{
  width: 100%;
  .verification-wrap{
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    background-color: #000;
    .verification-index-main{
      height: 100%;
      background-color: var(--background-color-4);
      .van-cell{
        background-color: #f5f5f5;
        border-radius: 16px;
      }
    }
    .close {
      line-height: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.btn-box{
  width: 100%;
}
</style>

<template>
  <div class="setOtherAccount-wrap">
    <div class="setOtherAccount-wrap-title">
      {{ $t("profile.add") }} {{ $t(`profile.${type}`) }}
    </div>
    <van-form>
      <div class="setOtherAccount-wrap-input df-styles">
        <p class="font14 main-text-2 margin-bottom8">
          {{ $t("profile.enter") }} {{ $t(`profile.${type}`) }}
        </p>
        <van-field v-model="enterValue" v-if="type === 'email'" :rules="[{ validator: emailRules, trigger: 'onChange' }]" />
        <van-field
          v-else
          v-model="enterValue"
          :rules="[{ validator: phoneRuls, trigger: 'onChange' }]"
          type="digit"
        >
          <template #label>
            <Country @callback="countryCallback"></Country>
          </template>
        </van-field>
      </div>
      <div class="setOtherAccount-wrap-input">
        <p class="font14 main-text-2 margin-bottom8">
          {{ $t("profile.enter") }} {{ $t("profile.verificationCode") }}
        </p>
        <van-field
          v-model="value"
          label=""
          readonly
          clickable
          @touchstart.native.stop="showKeyBoard = true"
        >
          <div slot="right-icon" @click="sendEmailSmsFn">
            <span v-show="countdown > 0">{{ countdown }}s</span>
            <span
              v-show="!(countdown > 0)"
              class="setOtherAccount-wrap-recent"
              :style="{color: checkCode ? 'var(--main-text-3)' : 'var(--primary-color)'}"
              >{{ $t("profile.getCode") }}</span
            >
          </div>
        </van-field>
      </div>
    </van-form>
    <van-button
      @click="onSubmit"
      :disabled="disabled"
      class="setOtherAccount-wrap-submit"
      >{{ $t("auth.submit") }}</van-button
    >
    <van-number-keyboard
      v-model="value"
      :show="showKeyBoard"
      :maxlength="6"
      @blur="showKeyBoard = false"
    />
  </div>
</template>

<script>
import Country from '@/components/auth/country/index.vue'

import { sendEmailSms, verifyEmailSms, updateEmailMobile } from '@/api/auth.js'
import { numberReg } from '@/constant/reg.js'

const emailReg = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
export default {
  name: 'SetOtherAccount',
  components: { Country },
  props: {
    verificationType: {
      type: String,
      default: ''
    }
  },
  computed: {
    type () {
      return this.$route.params.type
    },
    disabled () {
      return this.value.length !== 6
    },
    checkCode () {
      const reg = this.type === 'phone' ? numberReg : emailReg
      return !reg.test(this.enterValue)
    }
  },
  data () {
    return {
      enterValue: '',
      mobileCode: '',
      value: '',
      showKeyBoard: false,
      verificationTarget: '',
      accountStatus: null,
      expiresTime: '2023-08-02 17:00:00',
      countdown: 60,
      countdownTime: null,
      userInfo: {}
    }
  },
  mounted () {
    this.userInfo = this.$store.state.userInfo
    this.$store.dispatch('updateVerificationCountdown', 0).then(() => {
      this.checkCountdownFn()
    })
  },
  beforeDestroy () {
    clearTimeout(this.countdownTime)
    this.countdownTime = null
  },
  methods: {
    getTimestamp (date = '') {
      const newData = date ? new Date(date) : new Date()
      return Date.parse(newData)
    },
    checkCountdownFn () {
      // this.$store.state.verify.expiresTime
      // 本来是存后段传来的过期时间
      // 现在用来存前端倒计时的开始时间/暂停时间
      // this.$store.state.verify.countdown  倒计时还剩多少秒
      this.expiresTime = this.$store.state.verify.expiresTime
      this.countdown = this.$store.state.verify.countdown
      const nowDate = this.getTimestamp()
      const expiresDate = this.getTimestamp(this.expiresTime)
      const lossTime = Math.floor((nowDate - expiresDate) / 1000)
      console.log(lossTime, 'lossTime', this.countdown - lossTime)
      if (this.countdown - lossTime > 0) {
        this.countdown = this.countdown - lossTime
        this.getCountdownFn()
      } else {
        this.countdown = 0
      }
    },
    getCountdownFn () {
      if (this.countdownTime) {
        this.countdownTime = clearTimeout(this.countdownTime)
        this.countdownTime = null
      }
      this.countdown = this.countdown - 1
      if (this.countdown > 0) {
        this.countdownTime = setTimeout(() => {
          this.getCountdownFn()
        }, 1000)
      }
    },
    verifyEmailSmsFn () {
      const email = this.userInfo.email
      const mobile = this.userInfo.phone
      const mobileCode = this.userInfo.mobileCode
      const verifyCode = this.value
      const params = {}
      if (this.verificationType === 'phone') {
        params.email = email
      }
      if (this.verificationType === 'emial') {
        params.mobile_code = mobileCode
        params.mobile = mobile
      }
      params.verify_code = verifyCode
      return verifyEmailSms({
        ...params
      })
    },
    sendEmailSmsFn () {
      const reg = this.type === 'phone' ? numberReg : emailReg
      if (this.countdown > 0 || !reg.test(this.enterValue)) {
        return false
      }
      const params = {}
      const type = this.type === 'phone' ? 'mobile' : 'email'
      params[type] = this.enterValue
      if (this.verificationType === 'phone') {
        params.mobile_code = this.mobileCode
      }
      sendEmailSms({
        ...params
      }).then((res) => {
        this.$toast.clear()
        if (res) {
          const resData = res.data
          this.accountStatus = resData.account_status
          this.$store.dispatch(
            'updateVerificationCountdown',
            resData.expires_time
          )
          this.countdown = resData.expires_time
          this.getCountdownFn()
          // resData.expires_time
          this.$store.dispatch('updateVerificationExpiresTime', new Date())
          this.$store.dispatch(
            'updateVerificationAccountStatus',
            resData.account_status
          )
        }
      })
    },
    onSubmit () {
      const params = {
        // session_id: this.verify.sessionId,
        verify_code: this.value
      }
      const type = this.type === 'phone' ? 'mobile' : 'email'
      params[type] = this.enterValue
      if (this.verificationType === 'phone') {
        params.mobile_code = this.mobileCode
      }
      updateEmailMobile(params).then(res => {
        if (res) {
          this.$toast(this.$t('nav.successText'))
          setTimeout(() => {
            this.$router.push('/profile')
          }, 1000)
        }
      })
    },
    phoneRuls (val) {
      return new Promise((resolve) => {
        const tVal = numberReg.test(val)
        resolve(tVal)
      })
    },
    emailRules (val) {
      return new Promise((resolve) => {
        const tVal = emailReg.test(val)
        resolve(tVal)
      })
    },
    countryCallback (value) {
      this.mobileCode = value.mobile_code
    }
  }
}
</script>
<style lang="less">
.setOtherAccount-wrap {
  padding: 0 30px;

  &-title {
    margin-bottom: 48px;
    color: var(--main-text-1);
    font-family: Roboto;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 72px;
    /* 150% */
  }
  &-input {
    margin-bottom: 48px;
  }
  &-submit {
    margin-top: 48px;
    padding: 24px 0;
    width: 100%;
    height: 96px;
    border-radius: 16px;
    border: none;
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 48px; /* 150% */
    background: linear-gradient(
      135deg,
      var(--line-gradient-start-color) 0%,
      var(--line-gradient-end-color) 100%
    );
  }
  &-recent {
    color: var(--primary-color);
    text-align: right;
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 142.857% */
  }
}
</style>

<template>
  <div class="verificationCode-wrap">
      <div class="verification-index-main border-top-radius30">
        <div class="verification-index-header">
          <div class="font24 font-weight500 padding-left30 padding-right30 margin-bottom20">
            {{ $t('profile.security') }}
            {{ $t('profile.verification') }}
          </div>
        </div>
        <div class="padding-left30 padding-right30 padding-top20">
          <div>
            <div class="main-text-2 margin-bottom8"  v-if="verificationType === 'phone'">
              {{ $t('profile.emailCodeLabel') }}
            </div>
            <div class="main-text-2 margin-bottom8"  v-if="verificationType === 'email'">
              {{ $t('profile.phoneCodeLabel') }}
            </div>
            <van-field
              v-model="value"
              label=""
              readonly
              clickable
              @touchstart.native.stop="showKeyBoard = true"
            >
              <div slot="right-icon" @click="sendEmailSmsFn">
                <span v-show="countdown > 0">{{ countdown }}s</span>
                <span v-show="!(countdown > 0)" class="verification-index-wrapper-recent">{{$t('auth.recent')}}</span>
              </div>
            </van-field>
            <div class="main-text-2 margin-bottom8"  v-if="verificationType === 'phone'">
              {{ $t('profile.emailCodeTip') }}{{ hiddenEmailString }}
            </div>
            <div class="main-text-2 margin-bottom8"  v-if="verificationType === 'email'">
              {{ $t('profile.phoneCodeTip') }}{{ hiddenPhoneString }}
            </div>
            <van-button type="primary" block :disabled="value.length !== 6" class="submit-btn" @click="verifyEmailSmsFn">{{ $t('auth.submit') }}</van-button>
            <van-number-keyboard
              v-model="value"
              :show="showKeyBoard"
              :maxlength="6"
              @blur="showKeyBoard = false"
            />
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import hiddenString from '@/utils/hiddenString.js'
import { sendEmailSms, verifyEmailSms } from '@/api/auth.js'

export default {
  name: 'Country',
  props: {
    verificationType: {
      type: String,
      default: ''
    }
  },
  computed: {
    disabled () {
      return this.value.length !== 6
    }
  },
  data () {
    return {
      popupShow: false,
      value: '',
      showKeyBoard: false,
      verificationTarget: '',
      accountStatus: null,
      expiresTime: '2023-08-02 17:00:00',
      countdown: 60,
      countdownTime: null,
      userInfo: {},
      hiddenPhoneString: '',
      hiddenEmailString: ''
    }
  },
  mounted () {
    this.startFn()
  },
  beforeDestroy () {
    clearTimeout(this.countdownTime)
    this.countdownTime = null
  },
  methods: {
    startFn () {
      this.userInfo = this.$store.state.userInfo
      this.checkCountdownFn()
      if (this.verificationType === 'email') {
        this.setHiddenPhoneString()
      }
      if (this.verificationType === 'phone') {
        this.setHiddenEmailString()
      }
      this.sendEmailSmsFn()
    },
    getTimestamp (date = '') {
      const newData = date ? new Date(date) : new Date()
      return Date.parse(newData)
    },
    setHiddenEmailString () {
      this.hiddenEmailString = hiddenString(this.userInfo.email, 3, 4, 4)
    },
    setHiddenPhoneString () {
      this.hiddenPhoneString = hiddenString(this.userInfo.mobile, 3, 4, 4)
    },
    checkCountdownFn () {
      // this.$store.state.verify.expiresTime
      // 本来是存后段传来的过期时间
      // 现在用来存前端倒计时的开始时间/暂停时间
      // this.$store.state.verify.countdown  倒计时还剩多少秒
      this.expiresTime = this.$store.state.verify.expiresTime
      this.countdown = this.$store.state.verify.countdown
      const nowDate = this.getTimestamp()
      const expiresDate = this.getTimestamp(this.expiresTime)
      const lossTime = Math.floor((nowDate - expiresDate) / 1000)
      console.log(lossTime, 'lossTime', this.countdown - lossTime)
      if ((this.countdown - lossTime) > 0) {
        this.countdown = this.countdown - lossTime
        this.getCountdownFn()
      } else {
        this.countdown = 0
      }
    },
    getCountdownFn () {
      if (this.countdownTime) {
        this.countdownTime = clearTimeout(this.countdownTime)
        this.countdownTime = null
      }
      this.countdown = this.countdown - 1
      if (this.countdown > 0) {
        this.countdownTime = setTimeout(() => {
          this.getCountdownFn()
        }, 1000)
      }
    },
    verifyEmailSmsFn () {
      const email = this.userInfo.email
      const mobile = this.userInfo.mobile
      const mobileCode = this.userInfo.mobileCode
      const verifyCode = this.value
      const params = {}
      if (this.verificationType === 'phone') {
        params.email = email
      }
      if (this.verificationType === 'email') {
        params.mobile_code = mobileCode
        params.mobile = mobile
      }
      params.verify_code = verifyCode
      return verifyEmailSms({
        ...params
      }).then(res => {
        if (res) {
          this.$emit('onStep', 2)
          this.$toast.clear()
        }
      })
    },
    sendEmailSmsFn () {
      if (this.countdown > 0) {
        return false
      }
      const email = this.userInfo.email
      const mobile = this.userInfo.mobile
      const mobileCode = this.userInfo.mobile_code
      const params = {}
      if (this.verificationType === 'phone') {
        params.email = email
      }
      if (this.verificationType === 'email') {
        params.mobile_code = mobileCode
        params.mobile = mobile
      }
      sendEmailSms({
        ...params
      }).then((res) => {
        this.$toast.clear()
        if (res) {
          const resData = res.data
          this.accountStatus = resData.account_status
          this.$store.dispatch('updateVerificationCountdown', resData.expires_time)
          this.countdown = resData.expires_time
          this.getCountdownFn()
          // resData.expires_time
          this.$store.dispatch('updateVerificationExpiresTime', new Date())
          this.$store.dispatch('updateVerificationAccountStatus', resData.account_status)
        }
      })
    }
  }
}
</script>
<style lang="less">
.verificationCode-wrap{
  width: 100%;
  .verification-wrap{
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    background-color: #000;
    .verification-index-main{
      height: 100%;
      background-color: var(--background-color-4);
      .van-cell{
        background-color: #f5f5f5;
        border-radius: 16px;
      }
    }
    .verification-index-header {
      &-title {
        .logo {
          width: 160px;
          height: 64px;
          line-height: 64px;
          text-align: center;
          border-radius: 12px;
          background: rgba(91, 33, 203, 0.1);
          color: rgba(91, 33, 203, 0.3);
          font-family: Inter;
          font-size: 32px;
          font-style: normal;
          font-weight: 800;
        }
        .close {
          line-height: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .submit-btn {
    margin-top: 48px;
    padding: 24px 0;
    width: 100%;
    height: 96px;
    border-radius: 16px;
    border: none;
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 48px; /* 150% */
    background: linear-gradient(
      135deg,
      var(--line-gradient-start-color) 0%,
      var(--line-gradient-end-color) 100%
    );
  }
}
.verification-index-wrapper {
  &-message {
    display: block;
    font-family: Roboto;
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;
    color: var(--main-text-2);
  }
  &-recent {
    color: var(--primary-color);
    text-align: right;
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
  }
}
.btn-box{
  width: 100%;
}
</style>

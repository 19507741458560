<template>
  <div class="country-wrap">
    <div class="country ub ub-cen" @click="popupShowFn">
      <!-- <div class="icon-box">
        <img :src="countryObj.country_image_url" alt="" srcset="">
      </div> -->
      <div> + {{ countryObj.mobile_code }} </div>
      <div class="margin-left4">
        <van-icon name="arrow-down" />
      </div>
    </div>
    <van-popup v-model="popupShow" position="bottom" :overlay="false" :get-container="'.country-wrap'">
      <div class="country-list-wrap padding-top30">
        <div class="country-list border-top-radius30">
          <div class="country-header-box ub ub-btw padding-top30 padding-left30 padding-right30">
            <div class="icon-box" @click="onCancel">
              <img
                src="@/assets/images/system/left-arrow.svg"
                alt="back"
                title="left-arrow"
              />
            </div>
            <div class="font16">
              {{ $t('country.title') }}
            </div>
            <div class="icon-box" @click="onCancel" v-debounce>
              <img
                src="@/assets/images/system/cross.svg"
                alt="close"
                title="close"
              />
            </div>
          </div>
          <div  class="padding-top20 padding-left20 padding-right20 margin-bottom16">
            <van-search :placeholder="$t('search')" @clear="clearSearch" v-model="value" class="df-styles">
              <template #left-icon>
                <div class="ub ub-cen search-img">
                  <img src="@/assets/images/system/search.svg"/>
                </div>
              </template>
            </van-search>
          </div>
          <ul class="list-box padding-left30 padding-right30">
            <li v-for="(item,index) in filterCountry" :key="index" @click="onConfirm(item)">
              <!-- <span class="icon-box">
                <img :src="countryObj.country_image_url" alt="" srcset="">
              </span> -->
              <span>
                {{ item[countryName] }}
              </span>
              <span>
                {{ item.mobile_code }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useRequest } from 'vue-request'
import { contryList } from '@/api/auth.js'
export default {
  name: 'Country',
  emits: ['callback'],
  props: {},
  setup (_, { emit }) {
    const value = ref('')
    const columns = ref([])
    const countryObj = ref({
      mobile_code: ''
    })
    const popupShow = ref(false)
    const $v = window.$_Vue
    const store = $v.$store
    const router = $v.$router

    const filterCountry = computed(() => {
      let list = []
      if (value.value) {
        list = columns.value.filter((item) => {
          return item[countryName.value].indexOf(value.value) !== -1
        })
      } else {
        list = columns.value
      }
      return list
    })

    const countryName = computed(() => {
      const countryNameObj = {
        en_US: 'en',
        zh_CN: 'cn'
      }
      const locale = sessionStorage.getItem('locale') || store.state.locale
      return countryNameObj[locale]
    })

    const contryListFn = () => {
      useRequest(contryList, {
        cacheKey: '/country/list',
        onSuccess: res => {
          if (res) {
            columns.value = res.data
            countryObj.value = res.data[0]
            emit('callback', countryObj.value)
          }
          $v.$toast.clear()
        }
      })
    }

    const clearSearch = () => {
      value.value = ''
    }

    const onChange = (picker, value, index) => {
      console.log(`当前值：${value}, 当前索引：${index}`)
    }

    const popupShowFn = () => {
      popupShow.value = true
    }

    const onConfirm = (value) => {
      popupShow.value = false
      countryObj.value = value
      emit('callback', value)
    }

    const goHome = () => {
      router.push('/market')
    }

    const onCancel = () => {
      popupShow.value = false
    }

    onMounted(() => {
      if (!columns.value.length) {
        contryListFn()
      }
    })

    return {
      value,
      columns,
      countryObj,
      popupShow,
      filterCountry,
      clearSearch,
      onChange,
      onConfirm,
      goHome,
      onCancel,
      countryName,
      popupShowFn
    }
  }
}
</script>
<style lang="less">
.country-wrap{
  position: absolute;
  top: 0;
  left: 32px;
  height: 100%;
  color: var(--main-text-1);
  .margin-left4 {
    margin-left: 32px;
  }
  &::after {
    content: '';
    width: 2px;
    height: 40px;
    background-color: #E4E4E4;
    position: absolute;
    top: 50%;
    right: -32px;
    transform: translateY(-50%);
  }
  .country{
    background: #F5F5F5;
    height: 100%;
    .icon-box{
      width: 80px;
      height: 40px;
    }
  }
  .df-styles {
    .van-cell {
      align-items: center;
    }
    .van-search__content {
      background-color: var(--input-bg) !important;
    }
  }

.icon-box{
  img{
    width: 100%;
    height: 100%;
  }
}
.van-popup{
  background-color: rgba(0, 0, 0, 0);
}
.country-list-wrap{
  background-color: #000;
  box-sizing: border-box;
  color: var(--main-text-1);
  height: 100vh;
}
.country-list{
  box-sizing: border-box;
  height: calc(100vh - 60px);
  background-color: var(--background-color-4);
  width: 100%;
  .country-header-box{
    box-sizing: border-box;
  }
  .van-hairline--bottom::after{
    border-bottom-width: 0px;
  }
  .list-box{
    box-sizing: border-box;
    height: calc(100% - 23vh);
    width: 100%;
    overflow: scroll;
    li{
      height: 11vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
}
</style>

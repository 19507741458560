<template>
  <div class="security-wrap background-color-4 padding15">
    <p class="font24 font-weight500" v-if="verificationType === 'email'">{{$t('profile.email') + ' ' + $t('profile.verification')}}</p>
    <p class="font24 font-weight500" v-if="verificationType === 'phone'">{{$t('profile.phone') + ' ' + $t('profile.verification')}}</p>
    <!-- v-if="userInfo[verificationType]" -->
    <div class="card-box padding16 margin-top24" v-if="hiddenEmailString || hiddenPhoneString">
      <div class="ub">
        <div class="circle-box margin-right12" :class="{'phone_icon': verificationType === 'phone', 'email_icon': verificationType === 'email'}"></div>
        <div v-if="verificationType === 'email'">
          <p class="font16 main-text-1">{{ userInfo.email }}</p>
          <p class="font10 main-text-3">{{ $t('profile.added') }}: {{ formatDate(userInfo.create_time) }}</p>
        </div>
        <div v-if="verificationType === 'phone'">
          <p class="font16 main-text-1">{{ userInfo.mobile }}</p>
          <p class="font10 main-text-3">{{ $t('profile.added') }}: {{ formatDate(userInfo.create_time) }}</p>
        </div>
      </div>
    </div>
    <!-- v-if="!userInfo[verificationType]" -->
    <div class="none-account-box" v-else>
      <div class="main-box">
        <div class="rect-box" :class="{phone: verificationType === 'phone', email: verificationType === 'email'}"></div>
        <div class="font14 margin-top16 main-text-3 text-c"  v-if="verificationType === 'email'">
          {{ $t('profile.emailNoneTip') }}
          <!-- {{ hiddenEmailString }} -->
        </div>
        <div class="font14 margin-top16 main-text-3 text-c"  v-if="verificationType === 'phone'">
          {{ $t('profile.phoneNoneTip') }}
          <!-- {{ hiddenPhoneString }} -->
        </div>
      </div>
      <div class="footer-box padding15">
        <AddOtherAccount :verificationType="verificationType"></AddOtherAccount>
      </div>
    </div>
  </div>
</template>

<script>
import hiddenString from '@/utils/hiddenString.js'
import AddOtherAccount from '../addOtherAccount/index.vue'

import { Switch, Icon } from 'vant'
import Vue from 'vue'
import { mapGetters } from 'vuex'
Vue.use(Switch)
Vue.use(Icon)
export default {
  name: 'SecurityVerification',
  components: {
    AddOtherAccount
  },
  computed: {
    verificationType () {
      return this.$route.params.type
    },
    ...mapGetters(['locale'])
  },
  data () {
    return {
      userInfo: {},
      hiddenPhoneString: '',
      hiddenEmailString: ''
    }
  },
  mounted () {
    this.userInfo = this.$store.state.userInfo
    if (this.verificationType === 'email' && this.userInfo?.email) {
      this.setHiddenEmailString()
    }
    if (this.verificationType === 'phone' && this.userInfo?.mobile) {
      this.setHiddenPhoneString()
    }
    console.log(this.userInfo, 'this.userInfo ====', this.hiddenPhoneString, this.hiddenEmailString)
  },
  methods: {
    setHiddenEmailString () {
      this.hiddenEmailString = hiddenString(this.userInfo.email, 3, 4, 4)
    },
    setHiddenPhoneString () {
      this.hiddenPhoneString = hiddenString(this.userInfo.mobile, 3, 4, 4)
    },
    formatDate (dateString) {
      if (dateString) {
        const date = new Date(dateString)
        const options = { month: 'long', day: 'numeric', year: 'numeric' }
        return date.toLocaleDateString(this.locale.replace('_', '-'), options)
      } else {
        return '--'
      }
    }
  }
}
</script>
<style lang="less">
.email {
    background: url('~@/assets/images/profile/email.svg')no-repeat;
  }
  .phone {
    background: url('~@/assets/images/profile/phone.svg')no-repeat;
  }
  .email_icon {
    background: url('~@/assets/images/profile/email_icon.svg')no-repeat;
  }
  .phone_icon {
    background: url('~@/assets/images/profile/phone_icon.svg')no-repeat;
  }
.card-box{
  border-radius: 16px;
  border: 1px solid #ECEAEF;
  .circle-box{
    width: 48px;
    height: 48px;
    border-radius: 24px;
  }
}
.none-account-box{
  .rect-box{
    margin: 160px auto 0;
    width: 240px;
    height: 240px;
  }
  .footer-box{
    box-sizing: border-box;
    width: 100%;
    position: fixed;
    bottom: 40px;
    left: 0;
    .footer-btn{
      width:100%;
      height: 96px;
      background-color: #5B21CB;
      border-radius: 8px;
      color: #fff;
    }
  }
}
</style>
